var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticStyle: { padding: "0px" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "router-link",
                {
                  staticStyle: { "text-decoration": "none" },
                  attrs: { to: "/home" },
                },
                [
                  _c("v-icon", { staticClass: "icon-home" }, [
                    _vm._v("mdi-home-outline"),
                  ]),
                ],
                1
              ),
              _c(
                "v-icon",
                { staticClass: "mr-2 text-link", attrs: { small: "" } },
                [_vm._v("mdi-chevron-right")]
              ),
              _c(
                "b",
                {
                  staticStyle: {
                    color: "#8d8d99",
                    "font-size": "14px",
                    "font-weight": "400",
                    "letter-spacing": "0.01em",
                  },
                },
                [_vm._v("e-CAC")]
              ),
              _c(
                "v-icon",
                { staticClass: "mr-2 text-link", attrs: { small: "" } },
                [_vm._v("mdi-chevron-right")]
              ),
              _c(
                "router-link",
                { staticClass: "text-link", attrs: { to: "/ecac/mensagens" } },
                [_c("strong", [_vm._v("Mensagens e-CAC")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }