<template>
  <v-main style="padding: 0px">
    <v-row>
      <v-col>
        <router-link to="/home" style="text-decoration: none"
          ><v-icon class="icon-home">mdi-home-outline</v-icon></router-link
        >
        <v-icon small class="mr-2 text-link">mdi-chevron-right</v-icon>
        <b style="color: #8d8d99;font-size: 14px; font-weight: 400; letter-spacing: 0.01em">e-CAC</b>
        <v-icon small class="mr-2 text-link">mdi-chevron-right</v-icon>
        <router-link to="/ecac/mensagens" class="text-link"><strong>Mensagens e-CAC</strong></router-link>
      </v-col>
    </v-row>
  </v-main>
</template>
<script>
export default {
  name: 'MensagensNavBar',

  components: {},

  data() {
    return {
      header: 'MensagensNavBar',
    };
  },
};
</script>
<style scoped>
.icon-home {
  color: #2d5796 !important;
  font-size: 21px;
}
.text-link {
  text-decoration: none;
  color: #2d5796 !important;
  font-weight: 400 !important;
  letter-spacing: 0.01em !important;
  font-size: 14px !important;
}
</style>